// utils.ts

/**
 * Formats a number or string with commas.
 * @param {number | string} amount - The amount to be formatted.
 * @returns {string} - The formatted amount with commas.
 */
export const formatWithCommas = (amount?: number | string): string => {
    if (!amount) return '0.00';
    try {
        // Convert the input to a number if it is a string
        const amountNumber = typeof amount === 'string' ? parseFloat(amount)/100 : amount/100;
        
        // Check if the conversion was successful
        if (isNaN(amountNumber)) {
            throw new Error('Invalid amount');
        }

        // Use toLocaleString to format the number with commas
        return amountNumber.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } catch (error) {
        return 'Invalid amount';
    }
};

/**
 * Formats a price in cents to a dollar amount.
 * @param {number} cents - The price in cents.
 * @returns {string} - The formatted price in dollars.
 */
export const formatPrice = (cents: number): string => {
  return (cents / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

};
export const convertUTCDateToLocalDate = (date: Date) => {
  var localOffset = date.getTimezoneOffset() * 60000; // Convert offset to milliseconds
  var localDate = new Date(date.getTime() - localOffset); // Adjust for the local offset
  return localDate;
}
export const validatePasswordRules = (_: any, value: any) => {
    if (!value) {
      return Promise.reject(new Error('Please input your Password!'));
    }
    if (value.length < 8) {
      return Promise.reject(new Error('Password must be at least 8 characters long!'));
    }
    if (!/[A-Za-z]/.test(value) || !/[0-9]/.test(value)) {
      return Promise.reject(new Error('Password must contain both numeric and alphabetic characters!'));
    }
    return Promise.resolve();
  };