import React, { useEffect, useState } from 'react';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, EmailIcon, TwitterIcon, FacebookIcon, LinkedinIcon,PinterestIcon,
  WhatsappIcon,
  PinterestShareButton,
  WhatsappShareButton,} from 'react-share';
import { useLocation, useParams } from 'react-router-dom';
import { Modal, Input, Form } from 'antd';
import { FaSms } from 'react-icons/fa';
import { InputMask } from '@react-input/mask';
import '../styles/ShareButtonsStatic.scss';
import { useGetCampaignForUserQuery } from '../services/campaign';

interface ShareButtonsProps {
  schoolName: string;
  blurb: string;
  showEmail?: boolean;
  campaignId: string;
  userId: number;
}

const ShareButtonStatic: React.FC<ShareButtonsProps> = ({ schoolName, blurb,showEmail=true ,campaignId,userId}) => {
  const { campaign_id } = useParams<{ campaign_id: string }>();
  const { data, error, isLoading, isError } = useGetCampaignForUserQuery(campaignId|| '0', {
    skip: !campaignId,
  });
  const location = useLocation();
  const [campaignUrl, setcampaignUrl] =useState(`${process.env.REACT_APP_BASE_URL_FE}raise/${data?.campaign_link}${userId?`/${userId}`:''}`);
  const subject = `${schoolName} is Raising Funds`;
  const message = `${blurb} \n\nSupport us here: ${campaignUrl}`;
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isSmsModalVisible, setIsSmsModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const showEmailModal = () => {
    setIsEmailModalVisible(true);
  };

  const handleEmailOk = () => {
    if (!emailError) {
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
      window.location.href = mailtoLink;
      setIsEmailModalVisible(false);
    }
  };

  const handleEmailCancel = () => {
    setIsEmailModalVisible(false);
  };

  const showSmsModal = () => {
    setIsSmsModalVisible(true);
  };

  const handleSmsOk = () => {
    if (!phoneError) {
      const smsLink = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;
      window.location.href = smsLink;
      setIsSmsModalVisible(false);
    }
  };

  const handleSmsCancel = () => {
    setIsSmsModalVisible(false);
  };
  useEffect(() => {
    setcampaignUrl(`${process.env.REACT_APP_BASE_URL_FE}raise/${data?.campaign_link}${userId?`/${userId}`:''}`)
  }, [data?.campaign_link]);
  useEffect(() => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  }, [email]);

  useEffect(() => {
    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (phoneNumber && phoneNumber.length<10) {
      setPhoneError('Invalid phone number');
    } else {
      setPhoneError('');
    }
  }, [phoneNumber]);

  if (isError) return <></>;
 
  if (isLoading) return <></>;

  return (
   <>
      <div style={{display: "flex", gap:"10px"}} >
        <TwitterShareButton url={campaignUrl} title={subject} className="share-button">
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      {showEmail&&  <div className="share-button" onClick={showEmailModal}>
          <EmailIcon size={32} round />
        </div>}
        {/* <div className="share-button" onClick={showSmsModal}>
          <FaSms size={32} />
        </div> */}
        <FacebookShareButton url={campaignUrl} className="share-button">
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <LinkedinShareButton url={campaignUrl} title={subject} summary={message} className="share-button">
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
        <PinterestShareButton url={campaignUrl} media={`${window.location.origin}/path/to/image`} className="share-button">
          <PinterestIcon size={32} round />
        </PinterestShareButton>
        <WhatsappShareButton url={campaignUrl} title={subject} separator=":: " className="share-button">
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
      <Modal title="Share via Email" visible={isEmailModalVisible} onOk={handleEmailOk} onCancel={handleEmailCancel}>
        <Form.Item label="Email" validateStatus={emailError ? 'error' : ''} help={emailError}>
          <div className='share-button-form'>
          <Input placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
        </Form.Item>
      </Modal>
      {/* <Modal title="Share via SMS" visible={isSmsModalVisible} onOk={handleSmsOk} onCancel={handleSmsCancel}>
        <Form.Item label="Phone Number" validateStatus={phoneError ? 'error' : ''} help={phoneError}>
          <InputMask
mask="(___) ___-____"
replacement={{ _: /\d/ }}
placeholder="(xxx) yyy-yyyy"
onMask={(e) => {
  const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
  setPhoneNumber(value);
  setPhoneError('')
}}
style={{
  padding: "12px",
  border: "1px solid #ddd"
}}
         />
        </Form.Item>
      </Modal> */}
      </>
  );
};

export default ShareButtonStatic;
