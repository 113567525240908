import React, { useState, useEffect } from 'react';
import { Table, Pagination, Spin, message, Button, Drawer, Form, Input, Select } from 'antd';
import { useGetCampaignsQuery, useGetOrdersQuery, useSearchCampaignsQuery, useSearchUsersQuery, useTagUserForOrderMutation } from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import Modal from './Modal';
import { Campaign, Catalog, Item, User } from '../types.ts'; // Ensure correct import path
import '../styles/OrdersTable.scss';
import { convertUTCDateToLocalDate } from '../utils';
import { useGetUsersQuery } from '../services/admin/inventoryApi';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { setCampaignId, setUserId } from '../slices/admin/adminUserSlice';

const { Option } = Select;




const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount / 100);
};
interface UserOptionsProps { 
  userId:number,

  fullName: string;
  email: string;

}
interface OrderTableProps {
campaign_id?: number,
user_id?: number
}
const OrdersTable: React.FC<OrderTableProps> = ({campaign_id,user_id}) => {

  const dispatch = useDispatch();
  const campaignId = useSelector((state: RootState) => state.adminUser.campaignId);
  
  const userId = useSelector((state: RootState) => state.adminUser.userId);
  const [sortColumn, setSortColumn] = useState<string | null>('created_at');  // Default sorting by Created At
  const [sortOrder, setSortOrder] = useState<boolean>(false);  // Default sorting order (descending)
  const { currentPage, pageSize, onPageChange,onPageSizeChange } = usePagination();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [userOptions, setUserOptions] = useState<UserOptionsProps[]>([]); // State for user options in Select

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>(searchTerm);
  const [searchTermUser, setSearchTermUser] = useState<string>('');
  const [debouncedSearchTermUser, setDebouncedSearchTermUser] = useState<string>(searchTermUser);
  const { data:campaignData , isFetching:isCampaignsFetching,isLoading:isCampaignsLoading,status} = useSearchCampaignsQuery({
    key: "title",
    value: debouncedSearchTerm,
    limit: 1000,
  });
  const { data:allUsers , isFetching:isUsersFetching,isLoading:isUsersLoading,status:statusUsers} = useSearchUsersQuery({
    key: "full_name",
    value: debouncedSearchTermUser,
    limit: 1000,
  });
  const { data, error, isLoading, isFetching ,refetch} = useGetOrdersQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
   //@ts-ignore
   campaign_id: campaign_id||campaignId ,  // Use either campaignId or fallback
   //@ts-ignore
   user_id: user_id||userId ,  // Use either userId or fallback
   order_by: sortColumn ?? '',
   order_by_decending: sortOrder,  // Sort order boolean (true if descending)
  });


  const [tagUserForOrder, { isLoading:isLoadingTagUser, isError, isSuccess }] = useTagUserForOrderMutation();
  const [selectedUser, setSelectedUser] = useState(null);

  const [selectedRowData, setSelectedRowData] = useState<any>({
    recipient_first_name: "",
    user_id: "",
    email: "",
    recipient_last_name: "",
    recipient_address_1: "",
    recipient_address_2: "",
    recipient_address_city: "",
    recipient_address_state: "",
    recipient_address_zip: "",
    recipient_phone: 0,
    amount_donation: 0,
    amount_shipping: 0,
    amount_total: 0,
    product_ids: [],
    card_token: "",
    is_quote: false,
    quote_number: 0,
    id: 0,
    created_at: "",
    shipping_quote: 0,
    is_success: false,
    message: "",
    
  });
  const { data: usersData, error: usersError, isLoading: usersLoading } = useGetUsersQuery({
    skip: 0,
    limit: 1000 ,
    group_id_filter:selectedRowData?.campaign?.group_id ??0,
  });

 
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State to control the drawer visibility
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 992);
  // Handle table change for sorting
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if(sorter.order===undefined)return
    setSortColumn(sorter.field);  // Update the column being sorted
    const sortOrder = sorter.order=="ascend"?true:false;
    setSortOrder(!sortOrder);   // Update the sort order
  };
  useEffect(() => {
  

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 992);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    // Assuming the user data comes from another API or part of the current data
    if (usersData && usersData.data) {
      const users = usersData.data.map(user => ({
        userId: user.id,
        fullName: user.full_name,
        email: user.email,
      }));
      setUserOptions(users);
    }
  }, [usersData]);

  useEffect(() => {
    if (error) {
      message.error('Failed to load orders');
    }
  }, [error]);

  const handleRowClick = (record: any) => {
    setSelectedRowData(record);
    setIsDrawerOpen(true);
  };
  const onSubmit = async () => { console.log("v bnm,")
    try {
      let selectedOrder={id:selectedRowData.id,user_id:selectedUser};
     
      await tagUserForOrder(selectedOrder).unwrap();
      message.success('User Linked successfully!');
      handleCloseDrawer()
      console.log('user updated:', data);
      
    } catch (error) {
      // @ts-ignore
      message.error(error?.data?.detail || 'Something went wrong, Please try again.');
      console.error('Failed to add user:', error);
    }
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    setSelectedRowData({
      recipient_first_name: "",
      user_id: "",
      email: "",
      recipient_last_name: "",
      recipient_address_1: "",
      recipient_address_2: "",
      recipient_address_city: "",
      recipient_address_state: "",
      recipient_address_zip: "",
      recipient_phone: 0,
      amount_donation: 0,
      amount_shipping: 0,
      amount_total: 0,
      product_ids: [],
      card_token: "",
      is_quote: false,
      quote_number: 0,
      id: 0,
      created_at: "",
      shipping_quote: 0,
      is_success: false,
      message: ""

    });
  };

  const handleSearch = (value: string) => {
    if (usersData && usersData.data) {
      const users = usersData.data.map(user => ({
        userId: user.id,
        fullName: user.full_name,
        email: user.email,
      }));
      const filteredOptions = users.filter(option =>
        option.fullName.toLowerCase().includes(value.toLowerCase()) ||
        option.email.toLowerCase().includes(value.toLowerCase())
      );
      setUserOptions(filteredOptions);
      
    }
  
    
    
  };
 
    // Debounce effect to delay API call for smoother UX
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedSearchTerm(searchTerm);  // Set debounced search term after delay
      }, 300);  // Adjust the delay as needed
  
      return () => {
        clearTimeout(handler);  // Clear timeout if searchTerm changes before delay ends
      };
    }, [searchTerm]);
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedSearchTermUser(searchTermUser);  // Set debounced search term after delay
      }, 300);  // Adjust the delay as needed
  
      return () => {
        clearTimeout(handler);  // Clear timeout if searchTerm changes before delay ends
      };
    }, [searchTermUser]);
   // Handle search input in dropdown
   const handleCampaignSearch = (value: string) => {
    setSearchTerm(value);  // Update the search term
  };
  const handleUserSearch = (value: string) => {
    setSearchTermUser(value);  // Update the search term
  };
  const handleUserChange = (value: string | null) => {
    setSearchTermUser("");
    dispatch(setUserId(value));
  };
  const handleCampaignChange = (value: string | null) => {
    dispatch(setCampaignId(value));
    setSearchTerm("");
  };
  console.log(allUsers)
  const columns = [
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (text: string) => {
        const targetDate = convertUTCDateToLocalDate(new Date(text) as Date);
      console.log(targetDate)
        return isNaN(targetDate.getTime()) ? 'Invalid Date' : targetDate.toLocaleString();
      },
    },
    {
      title: 'Name',
      dataIndex: 'recipient_first_name',
      key: 'recipient_first_name',
      sorter: (a: any, b: any) => a.recipient_first_name.localeCompare(b.recipient_first_name),
      render: (_: any, record: any) => `${record.recipient_first_name} ${record.recipient_last_name}`,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
    },
    // {
    //   title: 'Is Quote',
    //   dataIndex: 'is_quote',
    //   key: 'is_quote',
    //   render: (text: boolean) => (text ? 'Yes' : 'No'),
    // },
    // {
    //   title: 'Is Success',
    //   dataIndex: 'is_success',
    //   key: 'is_success',
    //   render: (text: boolean) => (text ? 'Yes' : 'No'),
    // },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          Campaign Name
          <br />
          <Select
            placeholder="Select Campaign"
            allowClear
            showSearch
            onSearch={handleCampaignSearch}  // Trigger search on input
            style={{ width: 200, marginBottom: 16 }}
            onChange={handleCampaignChange}
            defaultValue={campaignId}
            optionFilterProp="children" // Search based on Option's children (full_name)
            notFoundContent={isCampaignsFetching && <Spin size="small" /> } 
            
          >
            {campaignData?.map((group: Campaign) => (
            //@ts-ignore
              <Option key={group.name} value={group.id}>
                {group.title}
              </Option>
            ))}
          </Select>
        </div>
      ),
      dataIndex: 'campaign_id',
      key: 'campaign_id',
      sorter: (a: any, b: any) => a?.campaign?.title?.toString().localeCompare(b?.campaign?.title),
      render: (_: any, record: any) => `${record?.campaign?.title?.toString()}`,
      
    },
    {
      title: (
        <div onClick={(e) => e.stopPropagation()}>
          User Name
          <br />
          <Select
            placeholder="Select User"
            allowClear
          showSearch
          onSearch={handleUserSearch} 
            style={{ width: 200, marginBottom: 16 }}
            onChange={handleUserChange}
            
            notFoundContent={isUsersFetching && <Spin size="small" /> } 
            optionFilterProp="children" // Search based on Option's children (full_name)
            defaultValue={userId}
          >
            {allUsers?.map((group: User) => (
            //@ts-ignore
              <Option key={group.name} value={group.id}>
                {group.full_name}
              </Option>
            ))}
          </Select>
        </div>
      ),
      dataIndex: 'user_id',
      key: 'user_id',
      sorter: (a: any, b: any) => a?.user?.full_name?.toString().localeCompare(b?.user?.full_name),
      render: (_: any, record: any) => `${record?.user?.full_name?.toString() ?? '-'}`,

    },
    // Add more columns as necessary
  ];
  return (
    <>
      {isLoading ? (
        <Spin tip="Loading..." />
      ) : (
        <>
             {/* <Select
            placeholder="Select Campaign"
            allowClear
            showSearch
            onSearch={handleCampaignSearch}  // Trigger search on input
            style={{ width: 200, marginBottom: 16 }}
            onChange={handleCampaignChange}
            defaultValue={campaignId}
            optionFilterProp="children" // Search based on Option's children (full_name)
            notFoundContent={isCampaignsFetching ? <Spin size="small" /> : null} 
            
          >
            {campaignData?.map((group: Campaign) => (
            //@ts-ignore
              <Option key={group.name} value={group.id}>
                {group.title}
              </Option>
            ))}
          </Select> */}
          {/* <Select
            placeholder="Select User"
            allowClear
          showSearch
            style={{ width: 200, marginBottom: 16 }}
            onChange={handleUserChange}
            optionFilterProp="children" // Search based on Option's children (full_name)
  filterOption={(input, option) =>
    //@ts-ignore
    option?.children.toLowerCase().includes(input.toLowerCase()) // Search by full_name
  }
            defaultValue={userId}
          >
            {allUsers?.data?.map((group: User) => (
            //@ts-ignore
              <Option key={group.name} value={group.id}>
                {group.full_name}
              </Option>
            ))}
          </Select> */}
          <Table
            onChange={handleTableChange}  // Trigger sorting on column change
            columns={columns}
            loading={isFetching}
            dataSource={data?.data || []}
            pagination={false}
            scroll={{ x: !isDesktop?'100vw':undefined }}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleRowClick(record),
            })}
            rowClassName="clickable-row"
          />
          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={data?.count}
            onChange={onPageChange}
            showSizeChanger={true}  // Allow page size change
            onShowSizeChange={(_, newSize) => onPageSizeChange(newSize)} // Handle page size changes
            pageSizeOptions={["5",'10', '20', '50', '100']}  // Set options for page size
          />
        </>
      )}
      <Drawer open={isDrawerOpen} onClose={handleCloseDrawer}>
        {selectedRowData && (
          <div >
            <p><strong>Name:</strong> {`${selectedRowData.recipient_first_name} ${selectedRowData.recipient_last_name}`}</p>

            <p><strong>Email:</strong> {selectedRowData.email}</p>
            <p><strong>Address 1:</strong> {selectedRowData.recipient_address_1}</p>
            <p><strong>Address 2:</strong> {selectedRowData.recipient_address_2}</p>
            <p><strong>City:</strong> {selectedRowData.recipient_address_city}</p>
            <p><strong>State:</strong> {selectedRowData.recipient_address_state}</p>
            <p><strong>ZIP:</strong> {selectedRowData.recipient_address_zip}</p>
            <p><strong>Phone:</strong> {selectedRowData.recipient_phone}</p>
            <p><strong>Student Name FreeForm:</strong> {selectedRowData.student_name_freeform}</p>
            <p><strong>Donation Amount:</strong> {formatCurrency(selectedRowData.amount_donation)}</p>
            <p><strong>Product IDs:</strong> {selectedRowData.product_ids.join(', ')}</p>
            <p><strong>Quote Number:</strong> {selectedRowData.quote_number}</p>
            <p><strong>Campaign ID:</strong> {selectedRowData.campaign_id}</p>
            <p><strong>User ID:</strong> 
              {  
                selectedRowData.user_id 
              }
              {selectedRowData.student_name_freeform&&<>
                <Select
                  showSearch
                  placeholder="Select a user"
                  optionFilterProp="children"
                  onSearch={handleSearch}
                  onChange={(value) => {setSelectedUser(value)}}
                  style={{ width: '100%' }}
                  
                >
                  {userOptions.map(option => (
                    <Option key={option.userId} value={option.userId}>
                      {option.fullName} ({option.email})
                    </Option>
                  ))}
                </Select>
                <Button type="primary" onClick={onSubmit} loading={isLoadingTagUser}>Tag User</Button>
                </>}
              
            </p>
            <p><strong>Created At:</strong> {new Date(selectedRowData.created_at).toLocaleString()}</p>
            <p><strong>Message:</strong> {selectedRowData.message}</p>
          </div>
        )}
      </Drawer>
    
    </>
  );
};

export default OrdersTable;
