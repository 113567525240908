import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../../store';
import { Campaign, Catalog, Group, Item, User } from '../../types.ts';


interface AddProductRequest extends Item {
  primary_image_url: string;
  fulfilment_type: string;
  cost: number;
  name: string;
  blurb: string;
  internal_name: string;
  internal_id: string;
  sku: string;
}
interface ParsedData {
  email_address: string;
  name: string;
}
interface AddUserRequest {
  "email": string,
  "is_active": boolean,
  "is_superuser": boolean,
  "full_name": string,
  "scope": string,
  "in_leaderboard": boolean,
  "recent_orders_enabled": boolean,
  "primary_image_url": string,
  "raise_goal": number,
  "blurb": string,
  "password": string,
  "groups": number[]
  "new_password":string,
  "pre_sale_id":number,
  "count_amount":string;
  "sold_amount":string;
}
interface UserResponse {
data?: User[];
count: number;
}
interface OrderResponse {
  length: number;
  data?: Item[];
  count: number;
  }

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const inventoryApi = createApi({
  reducerPath: 'inventoryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).adminUser.token;
      headers.set('ngrok-skip-browser-warning', `true`);
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Products', 'Catalogs', 'Groups','Users', 'Campaign', 'Orders','InviteStudents','searchCampaign','searchUsers'],
  endpoints: (builder) => ({
    getUsers: builder.query<UserResponse, { skip: number; limit: number, group_id?: number,group_id_filter?:number,user_id_filter?:string ,order_by?:string,order_by_decending?:boolean}>({
      query: ({ skip, limit, group_id ,group_id_filter,user_id_filter,order_by, order_by_decending}) => `users?skip=${skip}&limit=${limit}${group_id?`&group_id=${group_id}`:''}${group_id_filter?`&group_id_filter=${group_id_filter}`:''}${user_id_filter?`&user_id_filter=${user_id_filter}`:''}${order_by?`&order_by=${order_by}`:''}${order_by_decending!==null?`&order_by_decending=${order_by_decending}`:''}`,
      providesTags: ['Users'],
    }),
    searchUsers: builder.query<User[], { key: string;value:string; limit: number }>({
      query: ({ key,value, limit }) => `users/search/?key=${key}&value=${value}&limit=${limit}`,
      providesTags: ['searchUsers'],
    }),
    getOrders: builder.query<OrderResponse, { skip: number; limit: number, campaign_id?: number,user_id?: number ,order_by?:string,order_by_decending?:boolean}>({
      query: ({ skip, limit, campaign_id, user_id ,order_by, order_by_decending}) => `orders?skip=${skip}&limit=${limit}${campaign_id?`&campaign_id=${campaign_id}`:''}${user_id?`&user_id=${user_id}`:''}${order_by?`&order_by=${order_by}`:''}${order_by_decending!==null?`&order_by_decending=${order_by_decending}`:''}`,
      providesTags: ['Orders'],
    }),
    getInventoryProducts: builder.query<Item[], { skip: number; limit: number , catalog_id?: number}>({
      query: ({ skip, limit, catalog_id }) => `inventory/products/?skip=${skip}&limit=${limit}${catalog_id?`&catalog_id=${catalog_id}`:''}`,
      providesTags: ['Products'],
    }),
    GetGroupById: builder.query<Group, {group_id?: number}>({
      query: ({ group_id }) => `groups/${group_id}`,
      providesTags: ['Groups'],
    }),
    addProduct: builder.mutation<void, AddProductRequest>({
      query: (product) => ({
        url: `inventory/products/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: product,
      }),
      invalidatesTags: ['Products'],
    }),
    addUser: builder.mutation<void, AddUserRequest>({
      query: (product) => ({
        url: `users/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: product,
      }),
      invalidatesTags: ['Users'],
    }),
    addCampaign: builder.mutation<void, Campaign>({
      query: (product) => ({
        url: `campaigns/`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: product,
      }),
      invalidatesTags: ['Campaign'],
    }),
    editProduct: builder.mutation<void, Item>({
      query: (product) => ({
        url: `inventory/products/${product.id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: product,
      }),
      invalidatesTags: ['Products'],
    }),
    editUser: builder.mutation<void, User>({
      query: (user) => ({
        url: `users/${user.id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: user,
      }),
      invalidatesTags: ['Users'],
    }),
   
    getInventoryCatalogs: builder.query<Catalog[], { skip: number; limit: number }>({
      query: ({ skip, limit }) => `inventory/catalogs/?skip=${skip}&limit=${limit}`,
      providesTags: ['Catalogs'],
    }),
    addCatalog: builder.mutation({
        query: (newCatalog) => ({
          url: '/inventory/catalogs/',
          method: 'POST',
          body: newCatalog,
        }),
        invalidatesTags: ['Catalogs'],
      }),
      editCatalog: builder.mutation({
        query: (newCatalog) => ({
          url: `/inventory/catalogs/${newCatalog.id}`,
          method: 'PATCH',
          body: newCatalog,
        }),
        invalidatesTags: ['Catalogs'],
      }),
    getGroups: builder.query<Group[], { skip: number; limit: number;is_rep_group?:Boolean }>({
      query: ({ skip, limit,is_rep_group=true }) => `groups/?skip=${skip}&limit=${limit}${is_rep_group!==null?`&is_rep_group=${is_rep_group}`:''}`,
      providesTags: ['Groups'],
    }),
    addGroup: builder.mutation({
      query: (newGroup) => ({
        url: 'groups/',
        method: 'POST',
        body: newGroup,
      }),
      invalidatesTags: ['Groups'],
    }),
    inviteStudents: builder.mutation<void, { campaignId: string; data: ParsedData[] }>({
      query: ({campaignId,data}) => ({
        url: `campaigns/invite/students/${campaignId}`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['InviteStudents'],
    }),
    inviteUsers: builder.mutation<void, { campaignId: string; data: ParsedData[] }>({
      query: ({campaignId,data}) => ({
        url: `campaigns/invite/contacts/${campaignId}`,
        method: 'POST',
        body: data,
      }),
     
    }),
    editGroups: builder.mutation({
      query: (group) => ({
        url: `/groups/${group.id}`,
        method: 'PATCH',
        body: group,
      }),
      invalidatesTags: ['Groups', 'Users'],
    }),
    editCampaign: builder.mutation({
      query: (campaign) => ({
        url: `/campaigns/${campaign.id}`,
        method: 'PATCH',
        body: campaign,
      }),
      invalidatesTags: ['Campaign'],
    }),
    tagUserForOrder: builder.mutation({
      query: (order) => ({
        url: `/orders/${order.id}`,
        method: 'PATCH',
        body: order,
      }),
      invalidatesTags: ['Orders'],
    }),
    getCampaigns: builder.query<Campaign[], { skip: number; limit: number }>({
      query: ({ skip, limit }) => `campaigns/?skip=${skip}&limit=${limit}`,
      providesTags: ['Campaign'],
    }),
    searchCampaigns: builder.query<Campaign[], { key: string;value:string; limit: number }>({
      query: ({ key,value, limit }) => `campaigns/search/?key=${key}&value=${value}&limit=${limit}`,
      providesTags: ['searchCampaign'],
    }),
    getInviteStudents: builder.query<{ data: ParsedData[]; count: number }, { campaignId: string; skip: number; limit: number }>({
      query: ({ campaignId, skip, limit }) => `campaigns/invite/students/${campaignId}?skip=${skip}&limit=${limit}`,
      providesTags: ['InviteStudents'],
    }),
    getInviteCampaigns: builder.query<{ data: ParsedData[]; count: number }, { campaignId: string; skip: number; limit: number }>({
      query: ({ campaignId, skip, limit }) => `campaigns/invite/campaigns/${campaignId}?skip=${skip}&limit=${limit}`,
      providesTags: ['Campaign'],
    }),
    getReportSignups: builder.query<{ data: ParsedData[]; count: number }, { campaignId: string; skip: number; limit: number }>({
      query: ({ campaignId, skip, limit }) => `report/table/signups/${campaignId}?skip=${skip}&limit=${limit}`,
      providesTags: ['Campaign'],
    }),
    getLeaderBoard: builder.query<{ data: ParsedData[]; count: number }, { campaignId: string; skip: number; limit: number }>({
      query: ({ campaignId, skip, limit }) => `report/table/leaderboard/${campaignId}?skip=${skip}&limit=${limit}`,
      providesTags: ['Campaign'],
    }),
   
    downloadReport: builder.mutation<void, { campaignId: string; reportType: 'based_order' | 'based_user' | 'based_product' }>({
      query: ({reportType,campaignId}) => ({
        url: `report/campaign/${reportType}/${campaignId}`,
        method: 'GET',
        headers: {
          'Accept': 'text/csv',
        },
        responseHandler: (response) => response.blob(), 
      }),
    }),
  }),
  
});

export const { useGetInventoryProductsQuery, useGetInventoryCatalogsQuery, useGetGroupByIdQuery ,useGetGroupsQuery, useGetCampaignsQuery, useAddProductMutation, useEditProductMutation, useAddCatalogMutation, useEditCatalogMutation, useAddGroupMutation, useEditGroupsMutation, useGetUsersQuery,useAddCampaignMutation , useEditCampaignMutation, useEditUserMutation, useGetOrdersQuery, useAddUserMutation,useDownloadReportMutation , useInviteStudentsMutation, useInviteUsersMutation ,useGetInviteStudentsQuery,
  useGetInviteCampaignsQuery,useGetReportSignupsQuery,useGetLeaderBoardQuery,useTagUserForOrderMutation, useSearchCampaignsQuery,useSearchUsersQuery} = inventoryApi;
