import React, { useState, useCallback } from 'react';
import { useUploadFileMutation } from '../services/utils';
import { Button, Drawer, Popconfirm, Upload, message } from 'antd';
import { RcFile } from 'antd/lib/upload/interface';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css'; // Import ReactCrop styles
import '../styles/ImageUploader.scss';

interface ImageUploaderProps {
  onUpload: (url: string) => void;
  width?: number;
  height?: number;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onUpload, width=512, height=512 }) => {
  const [imageSrc, setImageSrc] = useState<string | ArrayBuffer | null>(null);
  const [crop, setCrop] = useState<Crop>({
    unit: 'px',
    width: width/4,
    height: height/4,
    x: 0,
    y: 0,
  });
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [uploadFile, { data,isLoading }] = useUploadFileMutation();
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);

  const handleCropChange = (newCrop: Crop) => {
    setCrop(newCrop);
  };

  const handleCropComplete = useCallback((croppedArea: Crop) => {
    console.log('Cropped area:', croppedArea);
  }, []);

  const handleFileChange = async (file: RcFile) => {
    if (!file) return;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setImageSrc(reader.result);
      setDrawerVisible(true);
    };
    return false;
  };

  const getCroppedImg = (image: HTMLImageElement, crop: Crop, fileName: string): Promise<Blob> => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width!;
    canvas.height = crop.height!;
    const ctx = canvas.getContext('2d');

        // Fill the canvas with white before drawing the image
        ctx!.fillStyle = 'white';
        ctx!.fillRect(0, 0, canvas.width, canvas.height);

    ctx!.drawImage(
      image,
      crop.x! * scaleX,
      crop.y! * scaleY,
      crop.width! * scaleX,
      crop.height! * scaleY,
      0,
      0,
      crop.width!,
      crop.height!
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  const handleUpload = async () => {
    if (!imageSrc || !imageRef || !crop.width || !crop.height) return;

    try {
      const croppedImageBlob = await getCroppedImg(imageRef, crop, 'croppedImage.jpeg');

      const formData = new FormData();
      formData.append('file', croppedImageBlob, 'image.jpg');

      const response = await uploadFile(formData).unwrap();
     console.log(response)

      setImageSrc(null);
      setDrawerVisible(false);

      // Assuming response contains the URL of the uploaded image
      onUpload(response.response);
    } catch (error) {
      message.error('Error uploading image');
      console.error('Error uploading image', error);
    }
  };

  return (
    <div style={{ marginTop: '5px' }}>
      <Upload
        accept=".jpg,.jpeg,.heic,.png"
        showUploadList={false}
        beforeUpload={handleFileChange}
      >
        <Button type="primary">Upload Image</Button>
      </Upload>

      <Drawer
        title="Crop Image"
        placement="right"
        onClose={() => setDrawerVisible(false)}
        visible={drawerVisible}
       width={800}
        bodyStyle={{ overflow: 'auto' }} // Prevents body scroll issue
      >
        {imageSrc && (
          <div style={{ position: 'relative', width: '100%', height: 'calc(100vh - 150px)' }}>
            <div >
            <ReactCrop
              crop={crop}
              onChange={handleCropChange}
              onComplete={handleCropComplete}
              aspect={width/height}
             
              ruleOfThirds
            >
              <img src={imageSrc as string}
               ref={setImageRef} 
               alt="Source" 
               
               />
            </ReactCrop>
            </div>
            <Popconfirm
    title="Upload this image"
    description="Are you sure to upload this image?"
    onConfirm={handleUpload}
   
    okText="Yes"
    cancelText="No"
  >
     <Button type="primary"  loading={isLoading} style={{ marginTop: 5 }}>
              Upload
            </Button>
  </Popconfirm>
           
          </div>
        )}
      </Drawer>
      
    </div>
  );
};

export default ImageUploader;
