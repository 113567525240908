import React, { useEffect, useState } from 'react';
import { Table, Pagination, Spin, message, Select, Button, Input } from 'antd';
import { useGetGroupsQuery, useGetUsersQuery, useSearchUsersQuery } from '../services/admin/inventoryApi';
import usePagination from '../hooks/usePagination';
import { Group, User } from '../types.ts'; // Ensure correct import path
import { Option } from 'antd/es/mentions';
import { useDispatch, useSelector } from 'react-redux';
import { setGroupId, setUserId } from '../slices/admin/adminUserSlice';
import { RootState } from '../store';



interface UsersTableProps {
  handleRowClick?: (record: User) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({ handleRowClick }) => {
  const dispatch = useDispatch();
  const groupId = useSelector((state: RootState) => state.adminUser.groupId);
  const userId = useSelector((state: RootState) => state.adminUser.userId);
  const [searchTermUser, setSearchTermUser] = useState<string>('');
  const [key, setKey] = useState<string>('');
  const [debouncedSearchTermUser, setDebouncedSearchTermUser] = useState<string>(searchTermUser);
  const { currentPage, pageSize, onPageChange, onPageSizeChange } = usePagination();
  const { data:allUsers , isFetching:isUsersFetching,isLoading:isUsersLoading,status:statusUsers} = useSearchUsersQuery({
    key: key,
    value: debouncedSearchTermUser,
    limit: 1000,
  });
  const [sortColumn, setSortColumn] = useState<string | null>('created_at');  // Default sorting by Created At
  const [sortOrder, setSortOrder] = useState<boolean>(false);  // Default sorting order (descending)

  // Fetch groups data for dropdown
  const { data: groupsData, error: groupsError } = useGetGroupsQuery({
    skip: 0,
    limit: 1000,
    //@ts-ignore
    is_rep_group: null,
  });

  const { data: usersData, error: usersError, isFetching,refetch,status } = useGetUsersQuery({
    skip: (currentPage - 1) * pageSize,
    limit: pageSize,
    //@ts-ignore
    group_id_filter: groupId,
    user_id_filter: userId ?? '',
    order_by: sortColumn ?? '',
    order_by_decending: sortOrder,  // Sort order boolean (true if descending)
  });
  const handleGroupChange = (value: string | null) => {
    dispatch(setGroupId(value));
  };
// Define table columns

const columns = [
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    sorter: true,  // Enable sorting for the Created At column
    render: (created_at: string) => new Date(created_at).toLocaleString(),  // Convert to local timezone
  },
  {
    title: (
      <div onClick={(e) => e.stopPropagation()}>
        Full Name
        <br />
        <Input
        placeholder="Search Input"
        allowClear
        style={{ width: 200, marginBottom: 16 }}
      value={key==="full_name"?searchTermUser:""}
        onChange={(e)=>{
          setKey("full_name")
          dispatch(setUserId(null));
          setSearchTermUser(e.target.value)
        }}
     />
        
      </div>
    ),
    dataIndex: 'full_name',
    key: 'full_name',
    sorter: true,  // Enable sorting
  },
  {
    title: 'Scope',
    dataIndex: 'scope',
    key: 'scope',
    sorter: true,  // Enable sorting
  },
  {
    title: (
      <div onClick={(e) => e.stopPropagation()}>
        Email
        <br />
        <Input
        value={key==="email"?searchTermUser:""}
        placeholder="Search Input"
        allowClear
        style={{ width: 200, marginBottom: 16 }}
      
        onChange={(e)=>{
          setKey("email")
          dispatch(setUserId(null));
          setSearchTermUser(e.target.value)
        }}
     />
        
      </div>
    ),
    dataIndex: 'email',
    key: 'email',
    sorter: true,  // Enable sorting
  },
  {
    title: 'Is Active',
    dataIndex: 'is_active',
    key: 'is_active',
    sorter: true,  // Enable sorting
    render: (text: boolean) => (text ? 'Yes' : 'No'),
  },
  {
    title: (
      <>
        Group Name
        <br />
        <Select
        placeholder="Select Group"
        allowClear
        style={{ width: 200, marginBottom: 16 }}
        onChange={handleGroupChange}
        defaultValue={groupId}
        showSearch
        optionFilterProp="children" // Search based on Option's children (full_name)
        filterOption={(input, option) =>
          //@ts-ignore
          option?.children.toLowerCase().includes(input.toLowerCase()) // Search by full_name
        }
      >
        {groupsData?.map((group: Group) => (
        //@ts-ignore
          <Option key={group.name} value={group.id}>
            {group.name}
          </Option>
        ))}
      </Select>
      </>
    ),
    dataIndex: 'groups',
    key: 'groups',
    sorter: false,  // Disable sorting for the Group Name column
    render: (groups: { name: string }[]) =>
      groups?.map((group) => group.name).join(', ') || '-',
    
  },
];
useEffect(() => {
  const handler = setTimeout(() => {
    setDebouncedSearchTermUser(searchTermUser);  // Set debounced search term after delay
  }, 300);  // Adjust the delay as needed

  return () => {
    clearTimeout(handler);  // Clear timeout if searchTerm changes before delay ends
  };
}, [searchTermUser]);
  useEffect(() => {
    if (usersError) {
      message.error('Failed to load users');
    }
  }, [usersError]);

  // const handleGroupChange = (value: string | null) => {
  //   dispatch(setGroupId(value));
  // };

  useEffect(() => {
    dispatch(setGroupId(null));  // Reset group filter on mount
  }, []);

  const fetchAllUsers = () => {
    dispatch(setGroupId(null));  // Clear the groupId filter to fetch all users
    dispatch(setUserId(null));
  };

  // Handle table change for sorting
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setKey('')
    dispatch(setUserId(null));
    setSortColumn(sorter.field);  // Update the column being sorted
    const sortOrder = sorter.order=="ascend"?true:false;
    setSortOrder(!sortOrder);   // Update the sort order
   
  };

  return (
    <>
     
        <>
         
          <Button onClick={fetchAllUsers} style={{ marginLeft: 16, marginBottom: 16 }}>
            Fetch All Users
          </Button>
          <Table
            columns={columns}
            loading={isFetching||isUsersFetching}
            dataSource={userId?usersData?.data ||   []:key? allUsers: usersData?.data||   []}
            pagination={false}
            rowKey="id"
            onRow={(record) => ({
              onClick: () => handleRowClick && handleRowClick(record),
            })}
            rowClassName="clickable-row"
            onChange={handleTableChange}  // Trigger sorting on column change
          />

          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={usersData?.count}  // Assuming `total` is set dynamically based on `usersData`
            onChange={onPageChange}
            showSizeChanger={true}  // Allow page size change
            onShowSizeChange={(_, newSize) => onPageSizeChange(newSize)}  // Handle page size changes
            pageSizeOptions={["5", '10', '20', '50', '100']}  // Set options for page size
          />
        </>
    
    </>
  );
};

export default UsersTable;
